<template>
  <div class="bidder-registration-info row">
    <h3 class="col-12 py-2 mb-3">Bidder Info</h3>

    <display-data
      class="col-12 px-1"
      label="Bidder Type"
      :content="bidderInfo.bidderType.name"
    ></display-data>
    <!-- Bidder 1 -->
    <div class="col-12 row mb-3">
      <h5 class="col-12 px-1 my-2">Main Bidder</h5>
      <display-data
        :class="displayFieldClass"
        label="Full Name"
        :content="bidderInfo.name1"
      ></display-data>
      <display-data
        :class="displayFieldClass"
        label="Contact Number"
        :content="bidderInfo.contact1"
      ></display-data>
      <display-data
        :class="displayFieldClass"
        label="Email"
        :content="bidderInfo.email1"
      ></display-data>
      <display-data
        :class="displayFieldClass"
        label="NRIC No."
        :content="bidderInfo.icNo1"
      ></display-data>
    </div>
    <!-- Bidder 2 -->
    <div v-if="bidderInfo.name2" class="col-12 row mb-3">
      <h5 class="col-12 px-1 my-2">Joint Bidder</h5>
      <display-data
        class="col-12 px-1"
        label="Relationship"
        :content="bidderInfo.relationship.name"
      ></display-data>
      <display-data
        :class="displayFieldClass"
        label="Full Name"
        :content="bidderInfo.name2"
      ></display-data>
      <display-data
        :class="displayFieldClass"
        label="Contact Number"
        :content="bidderInfo.contact2"
      ></display-data>
      <display-data
        :class="displayFieldClass"
        label="Email"
        :content="bidderInfo.email2"
      ></display-data>
      <display-data
        :class="displayFieldClass"
        label="NRIC No."
        :content="bidderInfo.icNo2"
      ></display-data>
    </div>

    <div class="col-12 row mb-3">
      <display-data
        :class="displayFieldClass"
        label="Bidder Address"
        :content="bidderInfo.address"
      ></display-data>
    </div>

    <!-- Documents -->
    <div class="col-12 row">
      <h5>Documents</h5>
      <download-files
        label="MyKad (Front)"
        class="col-12 mt-2 p-2"
        :downloadItem="bidderInfo.icFrontPhotos1"
        :filenamePrefix="`bid${bidderInfo.id}_mykad_front`"
      >
      </download-files>
      <download-files
        label="MyKad (Back)"
        class="col-12 mt-2 p-2"
        :downloadItem="bidderInfo.icBackPhotos1"
        :filenamePrefix="`bid${bidderInfo.id}_mykad_back`"
      >
      </download-files>

      <download-files
        v-if="bidderInfo.bidderType.id == BIDDER_TYPE.JOINT"
        label="Joint Bidder MyKad (Front)"
        class="col-12 mt-2 p-2"
        :downloadItem="bidderInfo.icFrontPhotos2"
        :filenamePrefix="`bid${bidderInfo.id}_joint_mykad_front`"
      >
      </download-files>
      <download-files
        v-if="bidderInfo.bidderType.id == BIDDER_TYPE.JOINT"
        label="Joint Bidder MyKad (Back)"
        class="col-12 mt-2 p-2"
        :downloadItem="bidderInfo.icBackPhotos1"
        :filenamePrefix="`bid${bidderInfo.id}_joint_mykad_back`"
      >
      </download-files>
      <download-files
        v-if="bidderInfo.bidderType.id == BIDDER_TYPE.JOINT"
        label="Relationship Evidence"
        class="col-12 mt-2 p-2"
        :downloadItem="bidderInfo.relationshipFiles"
        :filenamePrefix="`bid${bidderInfo.id}_relationship_evidence`"
      >
      </download-files>

      <download-files
        label="Deposit Slip"
        class="col-12 mt-2 p-2"
        :downloadItem="bidderInfo.depositSlips"
        :filenamePrefix="`bid${bidderInfo.id}_deposit_slip`"
      >
      </download-files>
      <download-files
        v-if="bidderInfo.bidderType.id == BIDDER_TYPE.COMPANY"
        label="SSM"
        class="col-12 mt-2 p-2"
        :downloadItem="bidderInfo.ssmFiles"
        :filenamePrefix="`bid${bidderInfo.id}_ssm`"
      >
      </download-files>
      <download-files
        v-if="bidderInfo.bidderType.id == BIDDER_TYPE.COMPANY"
        label="Authorization Letter"
        class="col-12 mt-2 p-2"
        :downloadItem="bidderInfo.authorizationLetters"
        :filenamePrefix="`bid${bidderInfo.id}_company_res`"
      >
      </download-files>
    </div>
  </div>
</template>

<script>
import { bidderType as BIDDER_TYPE } from "@/enums";
export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    DownloadFiles: () => import("@/components/GlobalComponents/DownloadFiles")
  },
  mixins: [],
  props: {
    bidderInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data: function () {
    return {
      BIDDER_TYPE,
      displayFieldClass: "col-12 sm-col-6 px-1"
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
